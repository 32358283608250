var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-card",staticStyle:{"width":"350px"}},[_vm._m(0),_c('section',{staticClass:"modal-card-body"},[_c('b-field',{attrs:{"label":"Old Password","custom-class":"is-small","type":{
        'is-danger': _vm.$v.changePassForm.oldPassword.$error,
      }},scopedSlots:_vm._u([{key:"message",fn:function(){return [(
            !_vm.$v.changePassForm.oldPassword.required &&
            _vm.$v.changePassForm.oldPassword.$error
          )?_c('div',[_vm._v(" "+_vm._s(_vm.$i18n.t('common.validations.requiredAlt'))+" ")]):_vm._e()]},proxy:true}])},[_c('b-input',{attrs:{"data-cy":"old-pass","type":"password","size":"is-small","password-reveal":""},on:{"blur":function($event){return _vm.$v.changePassForm.oldPassword.$touch()},"input":function($event){return _vm.$v.changePassForm.oldPassword.$touch()}},model:{value:(_vm.changePassForm.oldPassword),callback:function ($$v) {_vm.$set(_vm.changePassForm, "oldPassword", $$v)},expression:"changePassForm.oldPassword"}})],1),_c('b-field',{attrs:{"label":"New Password","custom-class":"is-small","type":{
        'is-danger': _vm.$v.changePassForm.newPassword.$error,
      }},scopedSlots:_vm._u([{key:"message",fn:function(){return [(
            !_vm.$v.changePassForm.newPassword.required &&
            _vm.$v.changePassForm.newPassword.$error
          )?_c('div',[_vm._v(" "+_vm._s(_vm.$i18n.t('common.validations.requiredAlt'))+" ")]):_vm._e()]},proxy:true}])},[_c('b-input',{attrs:{"data-cy":"new-pass","type":"password","size":"is-small","password-reveal":""},on:{"blur":function($event){return _vm.$v.changePassForm.newPassword.$touch()},"input":function($event){return _vm.$v.changePassForm.newPassword.$touch()}},model:{value:(_vm.changePassForm.newPassword),callback:function ($$v) {_vm.$set(_vm.changePassForm, "newPassword", $$v)},expression:"changePassForm.newPassword"}})],1),_c('b-field',{attrs:{"label":"Confirm Password","custom-class":"is-small","type":{
        'is-danger': _vm.$v.changePassForm.confirmedPassword.$error,
      }},scopedSlots:_vm._u([{key:"message",fn:function(){return [(
            !_vm.$v.changePassForm.confirmedPassword.required &&
            _vm.$v.changePassForm.confirmedPassword.$error
          )?_c('div',[_vm._v(" Confirm password must match with password field. ")]):_vm._e()]},proxy:true}])},[_c('b-input',{attrs:{"data-cy":"confirm-pass","type":"password","size":"is-small","password-reveal":""},on:{"blur":function($event){return _vm.$v.changePassForm.confirmedPassword.$touch()},"input":function($event){return _vm.$v.changePassForm.confirmedPassword.$touch()}},model:{value:(_vm.changePassForm.confirmedPassword),callback:function ($$v) {_vm.$set(_vm.changePassForm, "confirmedPassword", $$v)},expression:"changePassForm.confirmedPassword"}})],1)],1),_c('footer',{staticClass:"modal-card-foot is-justify-content-flex-end"},[_c('b-button',{attrs:{"label":"Close"},on:{"click":function($event){return _vm.$parent.close()}}}),_c('b-button',{attrs:{"data-cy":"confirm-change","label":"Confirm","type":"is-primary","disabled":_vm.$v.$invalid,"loading":_vm.changeLoading},on:{"click":_vm.modifyPass}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Change Password")])])
}]

export { render, staticRenderFns }